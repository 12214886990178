import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, Container, CssBaseline, TextField, Theme, Typography } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import { formatIncompletePhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js'
import * as Yup from 'yup'

import { AdministratorRequestDTO } from 'api'
import useApi from 'hooks/useApi'

import SignUpSubmitted from './SignUpSubmitted'

type StyleProps = {
  success: boolean
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  autofillHack: {
    '&:-webkit-autofill': {
      transitionDelay: '9999s',
      transitionProperty: 'background-color, color',
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: (props: StyleProps) => { return props.success ? green[500] : undefined },
    '&:hover': {
      backgroundColor: (props: StyleProps) => { return props.success ? green[700] : undefined },
    },
  },
  submitWrapper: {
    position: 'relative',
  },
  submitProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

export const signUpFormSchema = Yup.object().shape({
  firstName: Yup.string().required()
    .required('First Name is required.'),
  lastName: Yup.string()
    .required('Last Name is required.'),
  company: Yup.string()
    .required('Company Name is required'),
  phone: Yup.string()
    .required('Please enter a phone number')
    .test('is-valid-phone', 'Invalid phone number',
      function(value: string) {
        return new Promise((resolve, reject) => {
          if(!value || value.length === 0) {
            return resolve(true)
          }
          const phone = parsePhoneNumberFromString(value, 'US')
          if(phone) {
            const valid = phone.isValid()
            if(valid) {
              return resolve(true)
            }
          }
          return reject(this.createError())
        })
      }),
  email: Yup.string()
    .required('Email is required')
    .email('Pleaser enter a valid email'),
})

export interface SignUpFormValues {
  firstName: string
  lastName: string
  company: string
  phone: string
  email: string
}

export const SignUp = () => {
  const [signUpFormValues, setSignUpFormValues] = useState<AdministratorRequestDTO>()
  const [doNavigate, setDoNavigate] = useState<boolean>(false)
  const [showInfo, setShowInfo] = useState<boolean>(false)
  const signUpFetchState = useApi<AdministratorRequestDTO, never>(signUpFormValues ? 'v1/users/administrator' : undefined, undefined, {
    requestOptions: {
      method: 'POST',
      body: signUpFormValues ? JSON.stringify(signUpFormValues) : undefined,
    },
    dependencies: [signUpFormValues],
  })
  const isLoading = signUpFetchState.isLoading
  const classes = useStyles({ success: signUpFetchState.statusCode === 200 })

  useEffect(() => {
    if(doNavigate) {
      window.location.href = '/'
    }
  }, [doNavigate])

  useEffect(() => {
    if(signUpFetchState.statusCode) {
      setShowInfo(true)
    }
  }, [signUpFetchState.statusCode])

  const handleSubmit = (formVals: SignUpFormValues) => {
    setSignUpFormValues({
      firstName: formVals.firstName,
      lastName: formVals.lastName,
      company: formVals.company,
      phone: formVals.phone,
      email: formVals.email,
    })
  }
  if(showInfo) {
    return (<SignUpSubmitted onConfirm={() => { setDoNavigate(true) }} />)
  } else {
    return (
      <>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">
              Sign Up
            </Typography>
            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                company: '',
                phone: '',
                email: '',
              } as SignUpFormValues}
              validationSchema={signUpFormSchema}
              onSubmit={handleSubmit}
              render={(formikBag: FormikProps<SignUpFormValues>) => {
                const {
                  isSubmitting,
                  isValid,
                  errors,
                  touched,
                } = formikBag
                return (
                  <Form noValidate>
                    <Field
                      name="firstName"
                      render={({ field }: FieldProps) => (
                        <TextField
                          {...field}
                          autoComplete="given-name"
                          disabled={isLoading}
                          error={!!(errors.firstName && touched.firstName)}
                          fullWidth
                          helperText={touched.firstName && errors.firstName}
                          id="firstName"
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ className: classes.autofillHack }}
                          label="First Name"
                          margin="normal"
                          name="firstName"
                          required
                          variant="outlined"
                        />
                      )}
                    />
                    <Field
                      name="lastName"
                      render={({ field }: FieldProps) => (
                        <TextField
                          {...field}
                          autoComplete="family-name"
                          disabled={isLoading}
                          error={!!(errors.lastName && touched.lastName)}
                          fullWidth
                          helperText={touched.lastName && errors.lastName}
                          id="lastName"
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ className: classes.autofillHack }}
                          label="Last Name"
                          margin="normal"
                          name="lastName"
                          required
                          type="text"
                          variant="outlined"
                        />
                      )}
                    />
                    <Field
                      name="company"
                      render={({ field }: FieldProps) => (
                        <TextField
                          {...field}
                          autoComplete="organization"
                          disabled={isLoading}
                          error={!!(errors.company && touched.company)}
                          fullWidth
                          helperText={touched.company && errors.company}
                          id="company"
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ className: classes.autofillHack }}
                          label="Company Name"
                          margin="normal"
                          name="company"
                          required
                          type="text"
                          variant="outlined"
                        />
                      )}
                    />
                    <Field
                      name="phone"
                      render={({ field, form }: FieldProps) => (
                        <TextField
                          {...field}
                          autoComplete="tel"
                          disabled={isLoading}
                          error={!!(errors.phone && touched.phone)}
                          fullWidth
                          helperText={touched.phone && errors.phone}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ className: classes.autofillHack }}
                          label="Phone"
                          margin="normal"
                          name="phone"
                          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                            field.onBlur(e)
                            form.setFieldValue('phoneNumber', formatIncompletePhoneNumber(e.currentTarget.value, 'US'))
                          }}
                          placeholder="(555) 555-5555"
                          required
                          type="text"
                          variant="outlined"
                        />
                      )}
                    />
                    <Field
                      name="email"
                      render={({ field }: FieldProps) => (
                        <TextField
                          {...field}
                          disabled={isLoading}
                          error={signUpFetchState.isError || !!(errors.email && touched.email)}
                          fullWidth
                          helperText={touched.email && errors.email}
                          id="email"
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ className: classes.autofillHack }}
                          label="Email"
                          margin="normal"
                          name="email"
                          required
                          type="text"
                          variant="outlined"
                        />
                      )}
                    />
                    <Typography component="h5" variant="subtitle2">
                      By pressing Sign Up you agree with the Terms of Services and Privacy Policy
                    </Typography>
                    <div className={classes.submitWrapper}>
                      <Button
                        type="submit"
                        size="large"
                        variant="contained"
                        color="primary"
                        fullWidth
                        className={classes.submit}
                        disabled={!isValid || isSubmitting}
                      >
                        Sign Up
                        {isLoading && <CircularProgress size={24} className={classes.submitProgress} />}
                      </Button>
                    </div>

                  </Form>
                )
              }}
            />
          </div>
        </Container>

      </>
    )
  }
}

export default SignUp
